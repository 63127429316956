import Image from "next/image";
import { Cinzel_Decorative, Inter } from "next/font/google";

import { Button } from "./ui/button";
import bg4 from "../../public/background_4.png";
import img8 from "../../public/image_8.png";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import Link from "next/link";

const cinzel = Cinzel_Decorative({
  weight: ["400", "700", "900"],
  preload: false,
});

const inter = Inter({ subsets: ["latin"] });

const faqData = [
  {
    id: 1,
    question: "1.0. Is the game Free to Play?",
    answer:
      "Yes, Farmine Land is a free-to-play game, and you can play it at any time completely free. However, many of the game's features are intended for holders of our NFTs.",
  },
  {
    id: 2,
    question: "1.1. On which network does the game run?",
    answer:
      "Farmine Land is a web3 MMORPG currently running on the Immutable X network, a network with no gas fees for players. Additionally, it is currently the largest network for web3 games.",
  },
  {
    id: 3,
    question: "1.2. Has the game been launched?",
    answer:
      "Farmine Land is currently under development, with the first alpha test phase approaching for players who hold either the login code or the Alpha Tester NFT.",
  },
  {
    id: 4,
    question: "1.3. What are Lands?",
    answer:
      "Lands will represent over 80% of the game's total resource production. As a result, players will dedicate a significant portion of their gameplay to managing their Lands or acquiring resources for them.",
  },
  {
    id: 5,
    question: "1.4. Does the game have a token?",
    answer:
      "No, Farmine Land does not have a token. Players who play our game daily will be able to produce an in-game currency that can be exchanged for USDT or USDC in the future.",
  },
  {
    id: 6,
    question: "1.5. Can I play without owning a Land?",
    answer:
      "Yes, you can play in various ways within Farmine Land. You can rent a Land or be a Land helper, as there are multiple ways to play within our economy.",
  },
];

export function Works() {
  return (
    <div
      className="flex flex-col min-h-screen justify-center items-center py-10"
      style={{
        backgroundImage: `url(${bg4.src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <section className="flex flex-col items-center justify-center w-full px-4 sm:px-6 lg:px-8">
        <div className="w-full sm:w-4/5 md:w-3/5 bg-zinc-900 bg-opacity-30 backdrop-blur-lg rounded-lg p-4">
          <div className={cinzel.className}>
            <h5 className="text-center font-bold text-base text-yellow-500">
              HOW THE WORLDS WORKS
            </h5>
          </div>
        </div>

        <div
          className={`${inter.className} w-full flex flex-wrap justify-center mt-6`}
        >
          <div className="m-5 w-full sm:w-1/2 lg:w-2/5">
            <Image src={img8} alt="Start" className="w-full rounded-lg" />
            <div className={cinzel.className}>
              <div className="flex justify-center -mt-6">
                <Button className="py-2 px-4 bg-orange-950 hover:bg-yellow-900/90 text-yellow-500 border-4 border-yellow-500 rounded-sm">
                  <h2 className="px-6 text-center font-semibold text-sm sm:text-base lg:text-lg text-yellow-500">
                    Start
                  </h2>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${inter.className} w-2/3 flex flex-wrap justify-center mt-6`}
        >
          {faqData.map((item, index) => (
            <Accordion
              type="single"
              key={item.id}
              collapsible
              className="w-full bg-zinc-900 bg-opacity-30 backdrop-blur-lg mb-2"
            >
              <AccordionItem value={`item-${index + 1}`}>
                <AccordionTrigger className="pl-1 text-left font-bold text-base text-yellow-500">
                  {item.question}
                </AccordionTrigger>
                <AccordionContent className="p-3 text-left font-semibold text-xs sm:text-sm lg:text-base text-white">
                  {item.answer}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ))}
        </div>
        <div className="flex flex-wrap justify-center mt-6 gap-10">
          <Link
            target="_blank"
            href={"https://app.galxe.com/quest/FarmineLand"}
          >
            <img
              src={"/logos_parceiros/galxe.png"}
              alt={""}
              className="max-h-[58px] w-auto mt-10"
            />
          </Link>

          <Link
            target="_blank"
            href={"https://atlas3.io/project/farmine-landsrunes"}
          >
            <img
              src={"/logos_parceiros/attlas3.png"}
              alt={""}
              className="max-h-[58px] w-auto mt-10"
            />
          </Link>

          <Link
            target="_blank"
            href={
              "https://playtoearn.com/blockchaingame/farmine-land?rel=search"
            }
          >
            <img
              src={"/logos_parceiros/playearn.png"}
              alt={""}
              className="max-h-[58px] w-auto mt-10"
            />
          </Link>

          <Link
            target="_blank"
            href={"https://www.earnalliance.com/games/farmine-land"}
          >
            <img
              src={"/logos_parceiros/earnaliance.png"}
              alt={""}
              className="max-h-[58px] w-auto mt-10"
            />
          </Link>

          <Link
            target="_blank"
            href={"https://www.subber.xyz/farmineland-runes"}
          >
            <img
              src={"/logos_parceiros/subber.png"}
              alt={""}
              className="max-h-[58px] w-auto mt-10"
            />
          </Link>
        </div>
        <div className="w-2/3 flex flex-wrap justify-center mt-6 space-x-4">
          <Link
            target="_blank"
            href={"https://www.immutable.com/play/farmine-land"}
          >
            <Image
              src={"/logos_parceiros/immutable.png"}
              alt={""}
              width={10000}
              height={10000}
              className="w-[300px] mt-10"
            />
          </Link>
        </div>
      </section>
    </div>
  );
}
