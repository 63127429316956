"use client";
import Image from "next/image";
import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";
import { InfoMaps } from "@/components/InfoMaps";
import { Welcome } from "@/components/Welcome";
import { World } from "@/components/World";
import { Works } from "@/components/Works";
import info from "../../public/download.png";
import { useEffect, useState } from "react";
import useSales from "@/hooks/useSales";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/AuthContext";
import { NftCardHome } from "@/components/NftCardHome";
import { smallImages, mediumImages, largeImages } from "@/constants/images";
import { Cinzel_Decorative } from "next/font/google";
import { useRouter } from 'next/navigation'

const cinzel = Cinzel_Decorative({
  weight: ["400", "700", "900"],
  preload: false,
});

type AllTime = {
  timestamp: number;
  ogTimeRemain: number;
  whitelistTime: number;
  publicTime: number;
  roleTime: string;
};

export default function Home() {
  const { getTime } = useSales();
  const { address } = useAuth();
  const [timestamp, setTimestamp] = useState(0);
  const [allTime, setAlltime] = useState<AllTime | null>(null);
  const router = useRouter()

  const images = [
    "/sales/small_lands.png",
    "/sales/medium_lands.png",
    "/sales/large_lands.png",
  ];

  useEffect(() => {
    getTime().then((allTime) => {
      if (!allTime) return;
      const time = allTime.timestamp * 1000;
      setTimestamp(time);
      setAlltime(allTime);
    });
  }, [address]);

  return (
    <>
      <Header />

      <Welcome />

      <div className="min-h-screen md:h-screen w-full relative flex flex-col gap-8 items-center justify-center bg-heroSales bg-cover bg-no-repeat bg-center">
        <div className="bg-zinc-900 w-[90%] md:w-1/2 bg-opacity-30 backdrop-blur-lg p-4 sm:p-6 lg:p-8 mt-4 sm:mt-6 lg:mt-8 rounded-lg text-center">
          <div className={"__className_370a3a"}>
            <h5 className="font-bold text-center text-sm sm:text-base lg:text-lg text-yellow-500">
              Explore Our Lands and Start Your Adventure!
            </h5>
          </div>
          <div className={"__className_36bd41"}>
            <h2 className="mt-3 font-semibold text-xs sm:text-sm lg:text-base text-white">
              Discover the endless possibilities and wealth that await in
              Farmine Land. Own your exclusive plot and start building your
              dream farm today. Harness resources, breed animals, mine ores, and
              cultivate crops to make the most profit
            </h2>
            <Button className="mt-5 py-2 px-4 sm:py-3 sm:px-6 lg:py-4 lg:px-8 bg-orange-950 hover:bg-yellow-900/90 text-yellow-500 border-4 border-yellow-500 rounded-sm" onClick={() => router.push('/sales')}>
              <h2 className={`px-6 font-semibold text-xs sm:text-sm lg:text-base text-yellow-500 ${cinzel.className}`}>
                Buy Now
              </h2>
            </Button>
          </div>
        </div>


        <div className="flex flex-col md:flex-row relative w-full md:w-[1040px] items-center gap-6 ">
          {images.map((image, i) => {
            const videos =
              i === 0 ? smallImages : i === 1 ? mediumImages : largeImages;
            return <NftCardHome key={i} videos={videos} image={image} />;
          })}
        </div>
        <div className="absolute bottom-0 h-20 w-full blur-3xl bg-[#0D0906]" />
      </div>

      <World />

      <InfoMaps />

      <Works />

      <Footer />

      <div className="fixed bottom-4 right-4 sm:bottom-8 sm:right-8 md:bottom-10 md:right-10 cursor-pointer" onClick={() => router.push('https://wiki.farmine.land/game-client')}>
        <Image
          src={info}
          alt="Info Image"
          className="object-contain w-16 h-auto sm:w-24 md:w-32 lg:w-52"
        />
      </div>
    </>
  );
}
