'use client';

import Image from "next/image";
import { Cinzel_Decorative, Inter } from "next/font/google";
import { Card, CardContent } from "./ui/card";
import { 
  Carousel, 
  CarouselContent, 
  CarouselItem, 
  CarouselNext, 
  CarouselPrevious 
} from "./ui/carousel";

import bg2 from "../../public/background_2.png";

const IMAGES = [
  { id: 1, image: require("../../public/1.png") },
  { id: 2, image: require("../../public/2.png") },
  { id: 3, image: require("../../public/3.png") },
];

const cinzel = Cinzel_Decorative({
  weight: ["400", "700", "900"],
  preload: false,
});

const inter = Inter({ subsets: ["latin"] });

export function World() {
  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen py-10 px-4 sm:px-6 lg:px-8"
      style={{
        backgroundImage: `url(${bg2.src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="bg-zinc-900 bg-opacity-30 backdrop-blur-lg p-4 sm:p-6 lg:p-8 mt-4 sm:mt-6 lg:mt-8 rounded-lg w-full max-w-3xl">
        <div className={cinzel.className}>
          <h5 className="text-center font-bold text-sm sm:text-base lg:text-lg text-yellow-500">
            ENDLESS AND PRODUCTIVE LANDS
          </h5>
        </div>
        <div className={inter.className}>
          <h2 className="mt-3 text-center font-semibold text-xs sm:text-sm lg:text-base text-white">
            You can explore a variety of lands filled with infinite resources. With three unique biomes — 
            Winter, Tropical, and Desert — each terrain offers a unique and exciting experience. 
            Join this incredible journey and discover the wealth and adventures that await in every corner of Farmine Land.
          </h2>
        </div>
      </div>

      <div className="w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-3xl mt-14 mb-5 lg:mt-20">
        <Carousel className="max-w-full">
          <CarouselContent>
            {IMAGES.map((img) => (
              <CarouselItem key={img.id} className="md:basis-1/2 lg:basis-1/2">
                <div className="p-1">
                  <Card className="border-none">
                    <CardContent className="flex aspect-square items-center justify-center p-5">
                      <Image src={img.image} alt="" className="w-full h-full object-cover" />
                    </CardContent>
                  </Card>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="flex justify-center mt-4">
            <CarouselPrevious className="bg-zinc-900 border-yellow-700 text-yellow-700 mr-2" />
            <CarouselNext className="bg-zinc-900 border-yellow-700 text-yellow-700 ml-2" />
          </div>
        </Carousel>
      </div>

      <div className="bg-zinc-900 bg-opacity-30 backdrop-blur-lg p-4 sm:p-6 lg:p-8 mt-4 mb-5 sm:mt-8 lg:mt-8 rounded-lg w-full max-w-3xl">
        <div className={inter.className}>
          <h2 className="text-center font-semibold text-xs sm:text-sm lg:text-base text-white">
            Each of our Land NFTs represents an exclusive plot in the game.
          </h2>
        </div>
      </div>
    </div>
  );
}
