import type SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ArrowSwiper } from "./ui/ArrowSwiper";
import Image from "next/image";

type Props = {
  videos: string[];
  image: string;
};

export function NftCardHome({ videos, image }: Props) {
  const [hover, setHover] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const swiperRef = useRef<SwiperCore | null>(null);
  const [active, setActive] = useState(0);

  const onPrev = () => {
    setActive((prev) => (prev > 0 ? prev - 1 : prev));
    if (swiperRef.current) swiperRef?.current?.slideTo(active - 1);
  };

  const onNext = () => {
    setActive((prev) => prev + 1);
    if (swiperRef.current) swiperRef?.current?.slideTo(active + 1);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Aquí puedes ajustar el tamaño que consideras "mobile"
    };

    handleResize(); // Ejecutar una vez al montar el componente
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleMouseEnter = () => {
    if (!isMobile) {
      setHover(true);
    }
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setHover(false)}
      className="w-[303px] rounded-2xl relative overflow-hidden"
    >
      <div
        className={`${
          hover && "opacity-100"
        } opacity-0 duration-300 transition-all ease-in-out flex items-center justify-between gap-2 px-4 absolute top-[42%] w-full z-50`}
      >
        <ArrowSwiper onClick={onPrev} prev />
        <ArrowSwiper onClick={onNext} />
      </div>
      <Swiper
        spaceBetween={15}
        slidesPerView={1}
        initialSlide={0}
        grabCursor
        draggable={false}
        loop
        onSlideChange={(swiper) => setActive(swiper.activeIndex)}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {videos.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <div className="w-[303px]">
                <video
                  draggable={false}
                  controls={false}
                  muted
                  loop
                  autoPlay
                  className={`
              ${
                hover && "opacity-100 scale-110 mr-4"
              } opacity-0 w-full duration-300 transition-all ease-in-out z-20`}
                >
                  <source src={item} type="video/webm" />
                </video>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Image
        src={image}
        loader={() => image}
        alt="lands"
        width={30000}
        height={30000}
        draggable={false}
        className={`${
          hover && "opacity-0"
        } w-full absolute top-0 z-20 hover:scale-110 duration-300 transition-all ease-in-out`}
      />
    </div>
  );
}
