export const largeImages = [
  "/metadata/collection/land/image/desert/01.webm",
  "/metadata/collection/land/image/desert/02.webm",
  "/metadata/collection/land/image/snow/01.webm",
  "/metadata/collection/land/image/snow/02.webm",
  "/metadata/collection/land/image/tropical/01.webm",
  "/metadata/collection/land/image/tropical/02.webm",
];

export const mediumImages = [
  "/metadata/collection/land/image/desert/03.webm",
  "/metadata/collection/land/image/desert/04.webm",
  "/metadata/collection/land/image/desert/05.webm",
  "/metadata/collection/land/image/desert/06.webm",
  "/metadata/collection/land/image/snow/03.webm",
  "/metadata/collection/land/image/snow/04.webm",
  "/metadata/collection/land/image/snow/05.webm",
  "/metadata/collection/land/image/snow/06.webm",
  "/metadata/collection/land/image/tropical/03.webm",
  "/metadata/collection/land/image/tropical/04.webm",
  "/metadata/collection/land/image/tropical/05.webm",
  "/metadata/collection/land/image/tropical/06.webm",
];

export const smallImages = [
  "/metadata/collection/land/image/desert/07.webm",
  "/metadata/collection/land/image/desert/08.webm",
  "/metadata/collection/land/image/desert/09.webm",
  "/metadata/collection/land/image/desert/10.webm",
  "/metadata/collection/land/image/desert/11.webm",
  "/metadata/collection/land/image/snow/07.webm",
  "/metadata/collection/land/image/snow/08.webm",
  "/metadata/collection/land/image/snow/09.webm",
  "/metadata/collection/land/image/snow/10.webm",
  "/metadata/collection/land/image/snow/11.webm",
  "/metadata/collection/land/image/tropical/07.webm",
  "/metadata/collection/land/image/tropical/08.webm",
  "/metadata/collection/land/image/tropical/09.webm",
  "/metadata/collection/land/image/tropical/10.webm",
  "/metadata/collection/land/image/tropical/11.webm",
];
