import Image from "next/image";
import { Cinzel_Decorative, Inter } from "next/font/google";

import { Card, CardContent } from "./ui/card";
import bg3 from "../../public/background_3.png";
import img4 from "../../public/image_4.jpg"
import img5 from "../../public/image_5.jpg"
import img6 from "../../public/image_6.jpg"

const cinzel = Cinzel_Decorative({ 
  weight: ["400", "700", "900"],
  preload: false,
});

const inter = Inter({ subsets: ['latin'] })

export function InfoMaps() {
  return (
    <div 
      className="flex flex-col min-h-screen justify-center items-center py-10"
      style={{ backgroundImage: `url(${bg3.src})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <section className="flex flex-col items-center justify-center w-full px-4 sm:px-6 lg:px-8">
        <div className="w-full sm:w-4/5 md:w-3/5 bg-zinc-900 bg-opacity-30 backdrop-blur-lg rounded-lg p-4">
          <div className={cinzel.className}>
            <h5 className="text-center font-bold text-base text-yellow-500">
              A BIT... OF EVERYTHING!
            </h5>
          </div>
        </div>

        <div className={inter.className}>
          <div className="flex flex-nowrap justify-center ml-2 mt-5">
            <div className="m-1 w-full sm:w-1/2 lg:w-1/3">
              <Card className="bg-zinc-900 bg-opacity-30 backdrop-blur-lg border-0">
                <Image src={img4} alt={""} />
                <CardContent>
                <h5 className="p-3 text-center font-bold text-sm text-white">
                  COW, CHICKEN, PIG, SHEEP!
                </h5>

                <h5 className="p-3 text-center font-medium text-xs text-white">
                  On your Land, you can buy your own animals!<br/>
                  Put out to keep them healthy, and breed<br/>
                  them to have more animals!
                </h5>
                </CardContent>
              </Card>
            </div>

            <div className="m-1 w-full sm:w-1/2 lg:w-1/3">
              <Card className="bg-zinc-900 bg-opacity-30 backdrop-blur-lg border-0">
                <Image src={img5} alt={""} />
                <CardContent>
                <h5 className="p-3 text-center font-bold text-sm text-white">
                  MINE, CRAFT, MINE!
                </h5>

                <h5 className="p-3 text-center font-medium text-xs text-white">
                  You can enter the cave on your Land to mine!<br/>
                  some ores, they are important materials to<br/>
                  do some crafting later on!
                </h5>
                </CardContent>
              </Card>
            </div>

            <div className="m-1 w-full sm:w-1/2 lg:w-1/3">
              <Card className="bg-zinc-900 bg-opacity-30 backdrop-blur-lg border-0">
                <Image src={img6} alt={""} />
                <CardContent>
                <h5 className="p-3 text-center font-bold text-sm text-white">
                  BUILD YOUR FARM FROM ZERO!
                </h5>

                <h5 className="p-3 text-center font-medium text-xs text-white">
                  Prepare the soil to plant your crops<br/>
                  You can plan and organize what to plant in<br/>
                  order to make the most profit!
                </h5>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
