export function ArrowSwiper({
  prev,
  onClick,
}: {
  prev?: boolean;
  onClick: () => void;
}) {
  return (
    <svg
      className={`${prev && "rotate-180"} cursor-pointer`}
      onClick={onClick}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="-0.5"
        width="35" 
        height="35"
        rx="17.5"
        transform="matrix(1 0 0 -1 0 35)"
        fill="#302A27"
      />
      <rect
        x="0.5"
        y="-0.5"
        width="35"
        height="35"
        rx="17.5"
        transform="matrix(1 0 0 -1 0 35)"
        stroke="#3B3330"
      />
      <path
        d="M15.6665 23.8333L20.3332 17.9999L15.6665 12.1666"
        stroke="#D4B3A5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
