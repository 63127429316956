'use client'

import Image from "next/image";
import { Cinzel_Decorative, Inter } from "next/font/google";

import { Button } from "./ui/button";
import bg1 from "../../public/background_1.png";
import lg1 from "../../public/logo_animation_3.gif";
import { useRouter } from 'next/navigation'

const cinzel = Cinzel_Decorative({ 
  weight: ["400", "700", "900"],
  preload: false,
});

const inter = Inter({ subsets: ['latin'] });

export function Welcome() {
  const router = useRouter();
  return (
    <div 
      className="flex flex-col min-h-screen justify-center items-center"
      style={{ backgroundImage: `url(${bg1.src})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <section className="flex flex-col items-center justify-center h-full px-4 sm:px-6 lg:px-8 mt-10 mb-4">
        <div className="flex flex-col items-center">
          <Image 
            src={lg1} 
            alt="logo"
            className="w-40 h-16 sm:w-48 sm:h-20 md:w-64 md:h-28"
          />

          <div className="bg-zinc-900 bg-opacity-30 backdrop-blur-lg p-4 sm:p-6 lg:p-8 mt-4 sm:mt-6 lg:mt-8 rounded-lg text-center">
            <div className={cinzel.className}>
              <h5 className="font-bold text-center text-sm sm:text-base lg:text-lg text-yellow-500">BUILD YOUR OWN NAME</h5>
            </div>
            <div className={inter.className}>
              <h2 className="mt-3 font-semibold text-xs sm:text-sm lg:text-base text-white">
                Welcome to Farmine Land, an engaging 2D open-world MMORPG <br className="hidden sm:block" />
                where you can mine, craft items, and battle monsters to earn rewards. 
              </h2>

              <h2 className="mt-3 font-semibold text-xs sm:text-sm lg:text-base text-white">
                Harnessing the power of Immutable technology, <br className="hidden sm:block" />
                Farmine Land offers a unique and exciting adventure in an expansive open world web3 gaming.
              </h2>
            </div>
          </div>

          <div className={`${cinzel.className} flex flex-nowrap justify-center mt-5 flex-auto`}>
          <div className="m-1 w-full sm:w-1/2">
            <Button 
              className="mt-5 py-2 px-4 sm:py-3 sm:px-6 lg:py-4 lg:px-8 bg-orange-950 hover:bg-yellow-900/90 text-yellow-500 border-4 border-yellow-500 rounded-sm"
            >
              <h2 className="px-6 font-semibold text-xs sm:text-sm lg:text-base text-yellow-500" onClick={() => router.push('/register')}>
                Register
              </h2>
            </Button>
          </div>
          <div className="m-1 w-full sm:w-1/2">
            <Button 
              className="mt-5 py-2 px-4 sm:py-3 sm:px-6 lg:py-4 lg:px-8 bg-orange-950 hover:bg-yellow-900/90 text-yellow-500 border-4 border-yellow-500 rounded-sm"
            >
              <h2 className="px-6 font-semibold text-xs sm:text-sm lg:text-base text-yellow-500" onClick={() => router.push('https://wiki.farmine.land/game-client')}>
                Download now
              </h2>
            </Button>
          </div>
          </div>
        </div>
      </section>
    </div>
  );
}
